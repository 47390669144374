import { IAction, IBioMetrixInfo } from "../../type";

import React, {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import { CountdownTimer, Loader, RadialSeparators, ReactModal, Skeleton } from "components";
import {
  numberValidate,
  numberDecimalValidate,
  useFormatNumber,
  mergeStringWithSymbol,
  convertToDays,
  formatNumberWithCommas,
} from "utils";
import {
  ActiveCurrencyState,
  InfoState,
  IsOpenAssetLoadingState,
  SelectedOrderState,
  userSelectedCurrencyState,
  VisibleModifyState,
  defaultFormDatastate,
  goodTillCancelledState,
  userPersonalDetails,
  UsersLoadingState,
  VisibleCancelState,
  VerifyOrderModalType,
  VerfyOrderState,
  isLockinState,
  defaultModifyDatastate,
  isBalanceState,
} from "states";
import { useNetwork, useNotification, useThrottle, useWehAuthn } from "hooks";
import { APIS, Messages } from "constant";
import {
  actions,
  assestType,
  gov_guaranteed_loan,
  LOAN_ASSETS,
  ORDERBOOK_SERIES,
} from "views/exchange/constants";
import { useCurrency } from "hooks/currency";
import { Confirmation, ReactSwitch } from "@storybook";

import styles from "./Action.module.sass";
import "./Action.scss";
import { UseTransaction } from "views/MyWallet/store/hooks";
import {
  AssetPortfolioState,
  OrderPlacedExecutedCheckState,
  OrderPlacedState,
  primaryIssuanceState,
} from "@views/exchange/components/Orders/store/state";
import { ORDER_TYPE } from "@views/MyOrders/constants";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { DeviceBiometrics } from "@components/device-biometrics";
import { FortressAccountInfo } from "@views/MyWallet/store/state";
import BuysellConfirmation from "../buysellConfimation/buysellConfirmation";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import InsufficientFunds from "@views/MyWallet/conponent/InsufficientFunds/insufficientFunds";
import classNames from "classnames";
import VerifyOrder from "../VerifyOrder/VerifyOrder";
import { ALLOW_MPC_ENV } from "../../../../../../envs";
import { IssuanceSignDoc } from "@views/exchange/components";
// @ts-ignore
import { Json } from "@types/common";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { addMonths } from "date-fns";
import UseOrdersApi from "@views/exchange/components/Orders/store/hook";

const {
  ZERO_PRICE_ERROR,
  ZERO_LIMIT_ERROR,
  ZERO_QTY_ERROR,
  ORDER_PLACED,
  ORDER_EXECUTED,
  ORDER_ERROR_MESSAGE,
  LIMIT_BUY_MESSAGE,
  LIMIT_SELL_MESSAGE,
  ORDER_MODIFIED,
  BUYCONFIRMATION,
  SELLCONFIRMATION,
  ORDER_MODIFY_EXECUTED,
} = Messages;

enum ModalType {
  CONFIRM,
  APPROVED,
  DECLINE,
  TIMEUP,
  DEFAULT,
  MODIFIED,
  CANCELLED,
}

export const Action: FC<IAction> = ({
  type,
  activeTabIndex,
  content,
  StopLimit,
  classButton,
  buttonText,
  isOrderModifying,
}) => {

 //global states
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const setInfoData = useSetRecoilState(InfoState);
  const selectedOrder = useRecoilValue(SelectedOrderState);
  const setVisibleModify = useSetRecoilState(VisibleModifyState);
  const [goodTillCancelled, setGoodTillCancelled] = useRecoilState(
    goodTillCancelledState
  );

  // We need to update orderTab orderPlaceState to trigger order details api
  const [orderPlaced, setOrderPlaced] = useRecoilState(OrderPlacedState);
  const [primaryIssuance, setPrimaryIssuance] = useRecoilState(primaryIssuanceState);
  const UserLoading = useRecoilValue(UsersLoadingState);
  const {getUsersAssetPortfolio}=UseOrdersApi()

  const setorderPlacedExecutedCheck = useSetRecoilState(
    OrderPlacedExecutedCheckState
  );
  const personalData = useRecoilValue(userPersonalDetails);

  const { calculateWeightedYieldPer } = useAuctionHook();
  const { getWebAuthnSupported } = useWehAuthn();

  const currencyDetails = useRecoilValue(userSelectedCurrencyState);
  const isOpenAssetLoading = useRecoilValue(IsOpenAssetLoadingState);
  const formDataInitial = useRecoilValue(defaultFormDatastate);
  const [formData, setFormData] = useRecoilState(defaultModifyDatastate);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);
  const  setIsBalance =  useSetRecoilState(isBalanceState);

  const { phone, countryCode } = useRecoilValue(userPersonalDetails);
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const [initiateBiometric, setInitiateBiometrics] = useState(false);
  const { trackEvent } = useFullStoryTrackEvent();
  const isTimerCompleted = useRef<boolean>(false);

  const bondPoint =
    formData.price &&
    typeof formData.price === "string" &&
    parseFloat(formData.price.split(".")[0]) > 1
      ? "bond points"
      : "bond point";

  useEffect(() => {
    if (formDataInitial.price) {
      setFormData((prev) => ({ ...prev, price: formDataInitial.price }));
    }
  }, [formDataInitial]);

  const {
    id: activeCurrencyId,
    marketPrice,
    symbol,
    amount,
    currentLoanBalance,
    issuerDetails,
    musicId,
    fee,
  }: any = activeCurrency ?? {};

  const { issuerName } = issuerDetails || {};
  //local state
  const [dateTime, setDateTime] = useState(0);
  const [isBtnDisable, setBtnDisable] = useState(false);
  const setModalType = useSetRecoilState<ModalType>(VerifyOrderModalType);
  const setOpenVerfyOrder = useSetRecoilState(VerfyOrderState);
  const setVisibleCancelOrder = useSetRecoilState(VisibleCancelState);

  // hooks
  const { post: postOrder, loading } = useNetwork();
  const { put: modifyOrder, loading: modifyLoading } = useNetwork();
  const { get: getInfo } = useNetwork();
   const { post: postIssuanceUrl,loading:issuanceLoading} = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const {
    currencyConvertInNumber,
    amountToBondPoints,
    bondPointToAmount,
    formatCurrencyWithBillion,
  } = useCurrency();
  const { numberDecimal } = useFormatNumber();
  const { getFortressAccountInfo } = UseTransaction();
  const assetPortfolio = useRecoilValue(AssetPortfolioState);

  const { get: getOrders } = useNetwork();
  const isOrderBook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;

  const insufficientFundsArr = [
    "Failed to create order.: You do not have enough balance in your wallet",
    "Failed to create order......: You do not have enough balance in your wallet",
    "Failed to create order: You do not have enough balance in your wallet",
    "Failed to update order: You do not have enough balance in your wallet",
  ];

  const assetPrice = LOAN_ASSETS[activeCurrency?.type]
    ? isOrderBook
      ? amountToBondPoints(
          isGovGuarunteed
            ? selectedOrder?.government_guaranteed_loan_balance
            : selectedOrder?.current_loan_balance,
          selectedOrder?.price
        )
      : amountToBondPoints(currentLoanBalance, amount)
    : marketPrice || amount;

  useEffect(() => {
    if (symbol) {
      setFormData((prev: any) => {
        return {
          ...prev,
          symbol: isOrderBook ? selectedOrder?.symbol : symbol,
          price: isOrderModifying
            ? (
                Number(
                  LOAN_ASSETS[activeCurrency?.type]
                    ? amountToBondPoints(
                        isOrderBook
                          ? isGovGuarunteed
                            ? selectedOrder?.government_guaranteed_loan_balance
                            : selectedOrder?.current_loan_balance
                          : currentLoanBalance,
                        selectedOrder?.price
                      )
                    : selectedOrder?.price
                ) * currencyDetails?.rate
              ).toFixed(LOAN_ASSETS[activeCurrency?.type] ? 6 : 2)
            : LOAN_ASSETS[activeCurrency?.type]
            ? (assetPrice * currencyDetails?.rate).toFixed(6)
            : (assetPrice * currencyDetails?.rate).toFixed(2),
          qty: isOrderModifying ? Math.round(selectedOrder?.quantity) : "1",
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOrderModifying,
    assetPrice,
    selectedOrder?.price,
    symbol,
    currencyDetails?.rate,
  ]);

  useEffect(() => {
    const { price, limit, qty } = formData;
    if (activeTabIndex === 2) {
      if (!parseFloat((price || 0) as string) || !qty || !limit) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    } else {
      if (!parseFloat((price || 0) as string) || !qty) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    }
  }, [activeTabIndex, formData]);

  const handleWallet = useCallback(
    (TotalAmount: number) => {
      if (type === "BUY") {
        setInfoData((prev) => {
          return { ...prev, balance: prev.balance - TotalAmount };
        });
      }
    },
    [setInfoData, type]
  );

  const handleSubmit = useCallback(
    async (
      price: string | number,
      qty: string | number,
      limit: string | undefined,
      bioMetrixInfo: IBioMetrixInfo | undefined
    ) => {
      if (Number(price) === 0) {
        return errorNotification(ZERO_PRICE_ERROR);
      }
      if (Number(qty) === 0) {
        return errorNotification(ZERO_QTY_ERROR);
      }
      if (StopLimit) {
        if (Number(limit) === 0) {
          return errorNotification(ZERO_LIMIT_ERROR);
        }
        if (type === actions.BUY && Number(limit) < Number(price)) {
          return errorNotification(LIMIT_BUY_MESSAGE);
        }
        if (type === actions.SELL && Number(limit) > Number(price)) {
          return errorNotification(LIMIT_SELL_MESSAGE);
        }
      }
      setVisibleCancelOrder(false);
      setFormData((prev: any) => {
        return {
          ...prev,
          symbol,
          price: Number((assetPrice * currencyDetails.rate).toFixed(LOAN_ASSETS[activeCurrency?.type] ? 6 : 2)),
        };
      });

      const modifyApiUrl = ALLOW_MPC_ENV ? APIS.ORDERS_EXCHANGE_MODIFICATION : APIS.OrderHistory;
      const orderApiUrl = ALLOW_MPC_ENV ? APIS.ORDERS_EXCHANGE : APIS.Offers;
      const url = isOrderModifying ? `${modifyApiUrl}/${selectedOrder?.id}` : orderApiUrl;
      let payload = {};



      // this is to be used when SECRET_KEY from keychain will be available
      const SECRET_KEY: string = "";
      const modifiedCurrency = LOAN_ASSETS[activeCurrency?.type]
        ? Number(
            bondPointToAmount(
              isOrderBook
                ? isGovGuarunteed
                  ? selectedOrder?.government_guaranteed_loan_balance
                  : selectedOrder?.current_loan_balance
                : currentLoanBalance,
              price
            ).toFixed(2) || 0
          )
        : Number(currencyConvertInNumber(price));
      if (type === "BUY") {
        payload = isOrderModifying
          ? {
              price: modifiedCurrency,
              quantity: qty,
            }
          : {
              assetId: activeCurrencyId,
              quantity: qty,
              price: modifiedCurrency,
              type,
              goodTillCancelled: goodTillCancelled,
            };
      } else {
        payload = isOrderModifying
          ? {
              price: modifiedCurrency,
              quantity: qty,
            }
          : {
              assetId: activeCurrencyId,
              quantity: qty,
              price: modifiedCurrency,
              type,
              goodTillCancelled: goodTillCancelled,
            };
      }

      if (SECRET_KEY?.length > 0) {
        payload = { ...payload, payer: SECRET_KEY };
      }

       if (!ALLOW_MPC_ENV && activeCurrency?.isIssuanceEnabled) {
         payload = { ...payload, signDocId: primaryIssuance?.signDocId };
       }
      setDateTime(new Date().getTime());
      setorderPlacedExecutedCheck(false);

      if (isOrderModifying) {
        let countOldOpenOrder = 0;
        let countOldExecutedOrder = 0;
        getOrders(
          `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
        )
          .then((res: any) => {
            if (res) {
              countOldOpenOrder = res?.data?.length;
              getOrders(
                `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
              ).then((res) => {
                if (res) {
                  countOldExecutedOrder = res?.data?.length;
                  modifyOrder(url, payload)
                    .then((res) => {
                      if (res) {
                        if (insufficientFundsArr.includes(res?.message)) {
                          setIsBalance(true);
                          return;
                        }
                      }
                       const condition = ALLOW_MPC_ENV
                         ? res?.data?.orderRequestId
                         : res?.id;
                       if (condition) {
                         if (ALLOW_MPC_ENV) {
                           setOpenVerfyOrder({
                             ...res?.data,
                             VerificationType: "modifyOrder",
                           });
                           setModalType(ModalType.CONFIRM);
                         }
                         setVisibleModify(false);
                         getOrders(
                           `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
                         ).then((res: any) => {
                           const updatedOpenOrder = res?.data?.length;
                           getOrders(
                             `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
                           ).then((res: any) => {
                             if (res) {
                               const updatedExecutedOrder = res?.data?.length;
                               // if (
                               //   countOldOpenOrder > updatedOpenOrder ||
                               //   countOldExecutedOrder < updatedExecutedOrder
                               // ) {
                               //   successNotification(ORDER_MODIFY_EXECUTED);
                               // } else {
                               //   successNotification(ORDER_MODIFIED);
                               // }
                               setorderPlacedExecutedCheck(true);
                               setOrderPlaced(!orderPlaced);
                               trackEvent(`Order Modified`, {
                                 ...payload,
                               });
                               getInfo(APIS.Info).then((wallet) => {
                                 if (wallet?.data?.stellar) {
                                   setInfoData(wallet?.data?.stellar);
                                 }
                               });
                             }
                           });
                         });
                       } else {
                         errorNotification(res?.message ?? ORDER_ERROR_MESSAGE);
                       }
                    })
                    .catch((err) => {
                      errorNotification(err?.message ?? ORDER_ERROR_MESSAGE);
                    });
                }
              });
            }
          })
          .catch((error) => {
            console.error("Error retrieving old order count:", error);
          });
      } else {
        let countOldOpenOrder = 0;
        let countOldExecutedOrder = 0;
        getOrders(
          `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
        )
          .then((res: any) => {
            if (res) {
              countOldOpenOrder = res?.data?.length;
              getOrders(
                `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
              ).then((res) => {
                if (res) {
                  countOldExecutedOrder = res?.data?.length;
                  postOrder(url, payload)
                    .then((res) => {
                      if (res) {
                        if (insufficientFundsArr.includes(res?.message)) {
                          setIsBalance(true);
                          return;
                        }
                      }
                       const condition = ALLOW_MPC_ENV
                         ? res?.data?.orderRequestId
                         : res?.assetId;
                       if (condition) {
                         if (ALLOW_MPC_ENV) {
                           setOpenVerfyOrder(res?.data);
                           setModalType(ModalType.CONFIRM);
                         }

                         const totalAmount = Number(price) * Number(qty);
                         handleWallet(totalAmount);
                         setFormData((prev) => ({
                           ...prev,
                           qty: 1,
                         }));
                         getOrders(
                           `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
                         )
                           .then((res: any) => {
                             if (res) {
                               const updatedOpenOrder = res?.data?.length;
                               getOrders(
                                 `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
                               ).then((res) => {
                                 if (res) {
                                   const updatedExecutedOrder =
                                     res?.data?.length;
                                   // if (
                                   //   countOldOpenOrder === updatedOpenOrder ||
                                   //   countOldExecutedOrder < updatedExecutedOrder
                                   // ) {
                                   //   successNotification(ORDER_EXECUTED);
                                   // } else {
                                   //   successNotification(ORDER_PLACED);
                                   // }
                                   getFortressAccountInfo();
                                   setorderPlacedExecutedCheck(true);
                                   setOrderPlaced(!orderPlaced);
                                   trackEvent(`Order Placed`, {
                                     ...payload,
                                   });
                                   getInfo(APIS.Info).then((wallet) => {
                                     if (wallet?.data?.stellar) {
                                       setInfoData(wallet?.data?.stellar);
                                     }
                                   });
                                 }
                               });
                             }
                           })
                           .catch((error) => {
                             console.error(
                               "Error retrieving updated order count:",
                               error
                             );
                           });
                       } else {
                         errorNotification(
                           res?.message?.replace(/\./g, " ") ??
                             ORDER_ERROR_MESSAGE
                         );
                       }
                    })
                    .catch((err) => {
                      errorNotification(err?.message ?? ORDER_ERROR_MESSAGE);
                    });
                }
              });
            }
          })
          .catch((error) => {
            console.error("Error retrieving old order count:", error);
          });
      }
    },
    [StopLimit, activeCurrency?.isIssuanceEnabled, activeCurrency?.type, activeCurrencyId, assetPrice, bondPointToAmount, currencyConvertInNumber, currencyDetails.rate, currentLoanBalance, errorNotification, getFortressAccountInfo, getInfo, getOrders, goodTillCancelled, handleWallet, insufficientFundsArr, isGovGuarunteed, isOrderBook, isOrderModifying, modifyOrder, orderPlaced, postOrder, primaryIssuance?.signDocId, selectedOrder?.current_loan_balance, selectedOrder?.government_guaranteed_loan_balance, selectedOrder?.id, setInfoData, setModalType, setOpenVerfyOrder, setOrderPlaced, setVisibleCancelOrder, setVisibleModify, setorderPlacedExecutedCheck, symbol, trackEvent, type]
  );

  const  handleSignDoc  = useCallback(()=>{
     const { price, qty: quantity } = formData ?? {};
    const total = Number(formData?.price) * Number(formData?.qty);
    const assetId = activeCurrency?.id;
      postIssuanceUrl(APIS.PRIMARY_ISSUANCE, {
        price,
        quantity,
        total,
        assetId,
      }).then((res) => {
        if (res?.docUrl) {
          setVisibleCancel(false);

          setPrimaryIssuance({ ...res, signDocModal: true });
        }
        else{
          errorNotification(res?.message)
        }
      });
  },[activeCurrency?.id, formData])

  const handelOrderPlace = useCallback(() => {
    setIsbiometricLoading(true);
    if (!getWebAuthnSupported()) {

      handleSuccess();
    } else {
      setInitiateBiometrics(true);
    }
  }, []);

  const handleSuccess = useCallback(
    (bioInfo?: IBioMetrixInfo) => {
      const { price, qty, limit } = formData ?? {};
      handleSubmit(Number(price), Number(qty), limit, bioInfo);
      setInitiateBiometrics(false);
    },
    [formData, handleSubmit]
  );

  const handleFailure = useCallback(() => {
    setInitiateBiometrics(false);
  }, []);

  const throttledErrorNotification = useThrottle((message: string) => {
    errorNotification(message);
  }, 2000);

  const checkDecimalPlaces = (value: string, maxPlaces: number) => {
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1) {
      const decimalPart = value.slice(decimalIndex + 1);
      return decimalPart.length <= maxPlaces;
    }
    return true;
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, name: string) => {
      const { value } = e.target;
      const validQty = /^[0-9]{0,8}$/.test(value);
      const validPrice = /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,2})?$/.test(value);
      const validPriceForSba = /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,6})?$/.test(
        value
      );

      if (name === "qty" && !validQty) return;

      if (name === "price") {
        if (LOAN_ASSETS[activeCurrency?.type]) {
          if (!validPriceForSba) {
            if (!checkDecimalPlaces(value, 4)) {
              throttledErrorNotification(
                "You can only enter up to 6 decimal places."
              );
            }
            return;
          }
        } else {
          if (!validPrice) {
            if (!checkDecimalPlaces(value, 2)) {
              throttledErrorNotification(
                "You can only enter up to 2 decimal places. "
              );
            }
            return;
          }
        }
      }
      setFormData((prev: any) => ({ ...prev, [name]: value }));
    },
    [setFormData, activeCurrency?.type]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>, name: string) => {
      const { key } = e;
      if (name === "price") {
        if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      if (name === "limit") {
        if (key === "." && formData.limit?.toString().includes("."))
          e.preventDefault();
        else if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      if (name === "qty" && !numberValidate(key)) {
        e.preventDefault();
      }
    },
    [formData.limit]
  );

  const handleToggle = (checked: boolean) => {
    setGoodTillCancelled(checked);
  };

  const handleOrderAction = useCallback(
    async (choosePaymentType: string) => {
      const { price, limit, qty } = formData;
      if (
        type === "SELL" &&
        isOrderModifying &&
        Number(qty) >
          Number(assetPortfolio?.data?.quantity) +
            Number(selectedOrder.quantity)
      ) {
        return errorNotification("Not enough quantity available");
      }
      if (
        type === "SELL" &&
        (Number(qty) > Number(assetPortfolio?.data?.quantity) ||
          !Number(assetPortfolio?.data?.quantity || 0)) &&
        !isOrderModifying
      ) {
        return errorNotification("You’re attempting to sell more quantity than you currently have available for trade. Please adjust the quantity to proceed");
      }
      trackEvent(`Exchange Trading`, {
        type: type,
        assetSymbol: symbol,
        assetId: activeCurrencyId,
        price,
        limit,
        qty,
      });
      if (choosePaymentType === "wallet" && availableBalance?.balance < 0) {
        errorNotification("Wallet balance is not sufficient");
      } else {
        if (personalData?.isTradeEnabled === false) {
          return errorNotification("Trading is not allowed");
        }


        if (!ALLOW_MPC_ENV && activeCurrency?.isIssuanceEnabled && type==="BUY") {
          handleSignDoc();
        } else {
          handelOrderPlace();
        }
      }
    },
    [formData, type, isOrderModifying, assetPortfolio?.data?.quantity, selectedOrder.quantity, trackEvent, symbol, activeCurrencyId, availableBalance?.balance, errorNotification, personalData?.isTradeEnabled, activeCurrency?.isIssuanceEnabled]
  );

const handleButtonClick = useCallback(() => {
  const { qty } = formData;

  if (
    type === "SELL" &&
    (Number(qty) > Number(assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity) &&
    !isOrderModifying
  )) {
    return errorNotification("You’re attempting to sell more units than you currently have available for trade, and some units are still under the lockup period and cannot be sold. Please adjust the quantity to proceed.");
  }
  if (
    type === "SELL" &&
    (Number(qty) > Number(assetPortfolio?.data?.quantity) ||
      !Number(assetPortfolio?.data?.quantity || 0)) &&
    !isOrderModifying
  ) {
    return errorNotification("You’re attempting to sell more quantity than you currently have available for trade. Please adjust the quantity to proceed");
  }
  setVisibleCancel(true);
}, [formData, type, assetPortfolio?.data?.quantity, isOrderModifying, errorNotification]);

  const handleClose = useCallback(() => {
    setVisibleCancel(false);
    setInitiateBiometrics(false);
  }, []);

  const capitalizeFirstLetter = useCallback((str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLocaleLowerCase();
  }, []);

  const setModalVisible = useCallback(
    (loading: boolean, isModalOpen: boolean) => {
      setIsbiometricLoading(loading);
      setInitiateBiometrics(isModalOpen);
      setVisibleCancel(false);
    },
    []
  );

  const renderConfirmation = useMemo(() => {
    return (
      <BuysellConfirmation
        title={`${
          !isOrderModifying
            ? `Do you confirm to proceed with this ${capitalizeFirstLetter(
                type
              )} order?`
            : "Modify"
        }`}
        formData={formData}
        visible={visibleCancel}
        description={type === "BUY" ? BUYCONFIRMATION : SELLCONFIRMATION}
        handleModal={handleOrderAction}
        handleClose={handleClose}
        isOrderModifying={isOrderModifying}
        label={type === "BUY" ? "BUY" : "SELL"}
        type={type === "BUY" ? "buy" : "sell"}
        cancelLoading={
          isbiometricLoading || modifyLoading || loading || issuanceLoading
        }
      />
    );
  }, [
    isOrderModifying,
    capitalizeFirstLetter,
    type,
    visibleCancel,
    handleOrderAction,
    handleClose,
    isbiometricLoading,
    modifyLoading,
    loading,
  ]);

  const handleWheel = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const renderLockInTime = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
    if (completed && !isTimerCompleted.current) {
      // call the portfolio api
      isTimerCompleted.current = true;
      getUsersAssetPortfolio(activeCurrency?.id)
    }
    const time = `${!!days ? days + "D :" : ""} ${
      (hours < 10 ? "0" : "") + hours
    }H : ${(minutes < 10 ? "0" : "") + minutes}M : ${(seconds < 10 ? "0" : "") + seconds}S`;
    return (
      <div className="asset-lock-in-count">
        <CircularProgressbarWithChildren
          value={100} maxValue={100} className="count-circle" strokeWidth={10}>
          <RadialSeparators count={40} />
        </CircularProgressbarWithChildren>
        <div>
          <span className="mt-10">Time Remaining</span>
          <br/>
          <span className="time-color">{time}</span>
        </div>
      </div>
    );
  }, [activeCurrency]);


  const lockInTime = useMemo(() => {
      const durationObj = convertToDays(
        activeCurrency?.lockupPeriod,
        assetPortfolio?.data?.lockInTradeInfo?.tradeUnlockTime
      );
      return durationObj?.futureDate;
  }, [assetPortfolio, activeCurrency?.lockupPeriod]);

  return (
    <>
      <div
        className={cn(styles.action, {
          [styles.actionSba7]: LOAN_ASSETS[activeCurrency?.type],
        })}
      >
        <div className={styles.head}>
          <div className={styles.title}>
            {symbol === "" ? (
              <Skeleton />
            ) : musicId ? (
              symbol
            ) : (
              mergeStringWithSymbol(
                issuerName,
                isOrderBook ? selectedOrder?.symbol : symbol,
                "."
              )
            )}
          </div>
        </div>
        <div
          className={cn(styles.walletSection, {
            [styles.modifyingWalletSection]: isOrderModifying,
          })}
        >
          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              <i
                className="ri-wallet-3-line walletSvg"
                style={{ fontSize: "16px" }}
              />{" "}
              Wallet Balance :
            </div>
            <div className={styles.balanceValue}>{content}</div>
          </div>

          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              <i
                className="ri-coins-line walletSvg"
                style={{ fontSize: "16px" }}
              />
              {type === actions.SELL.toUpperCase()
                ? "Total Quantity Owned :"
                : "Quantity Owned :"}
            </div>
            <div
              className={cn(styles.balanceValue, {
                [styles.modifyingAssetQty]: isOrderModifying,
              })}
            >
              {assetPortfolio?.loading ? (
                <Skeleton height={15} />
              ) : (
                formatNumberWithCommas(assetPortfolio?.data?.quantity ?? 0)
              )}
            </div>
          </div>

          {type === actions.SELL.toUpperCase() && (
            <>
              <div
                className={cn(styles.walletBalance, {
                  [styles.modifyingWalletBalance]: isOrderModifying,
                })}
              >
                <div
                  className={cn(styles.walletHeading, {
                    [styles.modifyingWalletHeading]: isOrderModifying,
                  })}
                >
                  <i
                    className="ri-coins-line walletSvg"
                    style={{ fontSize: "16px" }}
                  />
                  Lockup Quantity :
                </div>
                <div
                  className={cn(styles.balanceValue, {
                    [styles.modifyingAssetQty]: isOrderModifying,
                  })}
                >
                  {assetPortfolio?.loading ? (
                    <Skeleton height={15} />
                  ) : (
                    formatNumberWithCommas(
                      assetPortfolio?.data?.lockInTradeInfo?.lockedQuantity
                    ) || formatNumberWithCommas(0)
                  )}
                </div>
              </div>

              <div
                className={cn(styles.walletBalance, {
                  [styles.modifyingWalletBalance]: isOrderModifying,
                })}
              >
                <div
                  className={cn(styles.walletHeading, {
                    [styles.modifyingWalletHeading]: isOrderModifying,
                  })}
                >
                  <i
                    className="ri-coins-line walletSvg"
                    style={{ fontSize: "16px" }}
                  />
                  Available For Trade :
                </div>
                <div
                  className={cn(styles.balanceValue, {
                    [styles.modifyingAssetQty]: isOrderModifying,
                  })}
                >
                  {assetPortfolio?.loading ? (
                    <Skeleton height={15} />
                  ) : (
                    formatNumberWithCommas(
                      assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity
                    ) || formatNumberWithCommas(0)
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {type !== 'BUY' &&
          assetPortfolio?.data?.lockInTradeInfo?.tradeUnlockTime &&
          !assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity ? (
            <div className="asset-lock-in-time-container">
              <p className="asset-lock-in-des">
                This asset is currently under a lock-in period and cannot be
                sold at this time.
              </p>
              <CountdownTimer
                dateTime={lockInTime}
                renderer={renderLockInTime}
              />
            </div>
          )
          :
          <>
          <>
            <label className={styles.field}>
              <div className={styles.label}>Price</div>
              {!isOpenAssetLoading ? (
                <input
                  type="text"
                  name="price"
                  value={formData.price}
                  maxLength={18}
                  disabled={activeTabIndex === 1}
                  className={styles.input}
                  style={{
                    color: `${activeTabIndex === 1 ? "#777E90" : "unset"}`,
                  }}
                  min={1}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "price")
                  }
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleKeyPress(e, "price")
                  }
                  autoComplete="off"
                />
              ) : (
                <Skeleton />
              )}

              <div
                className={cn(styles.currency, {
                  [styles.bondPoint]: LOAN_ASSETS[activeCurrency?.type],
                })}
              >
                {LOAN_ASSETS[activeCurrency?.type]
                  ? bondPoint
                  : currencyDetails.code}
              </div>
            </label>
          </>
          {LOAN_ASSETS[activeCurrency?.type] && (
            <div
              className={cn(styles.walletBalance, {
                [styles.modifyingWalletBalance]: isOrderModifying,
              })}
            >
              <div
                className={cn(styles.walletHeading, {
                  [styles.modifyingWalletHeading]: isOrderModifying,
                })}
              >
                {/* <i
            className="ri-wallet-3-line walletSvg"
            style={{ fontSize: "16px" }}
          />{" "} */}
                Equivalent Value :
              </div>
              {/* const amount = bondPointToAmount(auctionDetails?.currentLoanBalance,
          maxBidPrice) || 0; const avgYieldPer =
          calculateWeightedYieldPer(auctionDetails, amount, 6); return (
          <>
            Equivalent value : {formatCurrencyWithBillion(amount, 2, true)}
            <br />
            totalWeightedLoanBalance
            Equivalent weighted avg. yield : {avgYieldPer}%
          </> */}
              <div className={styles.balanceValue}>
                {formatCurrencyWithBillion(
                  bondPointToAmount(
                    isOrderBook
                      ? isGovGuarunteed
                        ? selectedOrder?.government_guaranteed_loan_balance
                        : selectedOrder?.current_loan_balance
                      : currentLoanBalance,
                    formData?.price
                  ) || 0,
                  2,
                  true,
                  true
                )}
              </div>
            </div>
          )}

          {StopLimit && (
            <label className={styles.field}>
              <div className={styles.label}>Limit</div>
              <input
                type="text"
                name="limit"
                value={formData.limit}
                className={styles.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, "limit")
                }
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleKeyPress(e, "limit")
                }
              />
              <div className={styles.currency}>
                {" "}
                {LOAN_ASSETS[activeCurrency?.type]
                  ? "bond points"
                  : currencyDetails.code}
              </div>
            </label>
          )}
          <label className={styles.field}>
            <div className={styles.label}>Qty</div>
            <input
              type="number"
              onWheel={handleWheel}
              name="qty"
              value={formData.qty}
              className={styles.input}
              min={1}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "qty")
              }
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                handleKeyPress(e, "qty")
              }
              disabled={LOAN_ASSETS[activeCurrency?.type]}
            />
          </label>
          <label className={styles.field}>
            <div className={styles.label}>Total</div>

            <div className={styles.total}>
              {LOAN_ASSETS[activeCurrency?.type]
                ? formatNumberWithCommas(
                    (Number(formData.price) * Number(formData.qty)).toFixed(6)
                  )
                : formatNumberWithCommas(
                    (Number(formData.price) * Number(formData.qty)).toFixed(2)
                  )}
            </div>
            <div className={styles.currency}>
              {LOAN_ASSETS[activeCurrency?.type]
                ? bondPoint
                : currencyDetails.code}
            </div>
          </label>
          {LOAN_ASSETS[activeCurrency?.type] && (
            <div
              className={cn(styles.walletBalance, {
                [styles.modifyingWalletBalance]: isOrderModifying,
              })}
            >
              <div
                className={cn(styles.walletHeading, {
                  [styles.modifyingWalletHeading]: isOrderModifying,
                })}
              >
                {/* <i
            className="ri-wallet-3-line walletSvg"
            style={{ fontSize: "16px" }}
          />{" "} */}
                Equivalent yield :
              </div>
              <div className={styles.balanceValue}>
                {/* 10.260000% const */}
                {formatNumberWithCommas(
                  calculateWeightedYieldPer(
                    {
                      metadata: {
                        totalWeightedLoanBalance:
                          activeCurrency?.totalWeightedLoanBalance,
                      },
                    },
                    bondPointToAmount(currentLoanBalance, formData?.price) ||
                      0,
                    6
                  )
                )}
                {""}%
              </div>
            </div>
          )}
          {fee[type.toLowerCase()] === 0 ? (
            ""
          ) : (
            <p className={styles.fees}>
              {" "}
              {fee?.[type.toLowerCase()]}% fees is included in total
            </p>
          )}
          {!isOrderModifying && (
            <div className={styles.goodTillCancel}>
              <div>
                <p className={styles.goodTillCancelTitle}>
                  Good till cancelled <i className="ri-information-fill"></i>
                </p>
                {goodTillCancelled ? (
                  <p className={styles.goodTillCancelSubTitle}></p>
                ) : (
                  <p className={styles.goodTillCancelSubTitle}>
                    This order will expire at 12:00 AM EST
                  </p>
                )}
              </div>

              <div>
                <ReactSwitch
                  checked={goodTillCancelled}
                  handleChange={handleToggle}
                  onColor="#3772FF"
                  className={
                    goodTillCancelled
                      ? "react-switch-handle-checked"
                      : "react-switch-handle-unchecked"
                  }
                />
              </div>
            </div>
          )}
        </>
        }

        {/* {(type === "BUY" ||
          !(!assetPortfolio?.data?.lockInTradeInfo?.tradeUnlockTime &&
            !assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity)) && (

        )} */}


        <button
          disabled={
            (type === "SELL" &&
              !assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity) ||
            isBtnDisable ||
            !(
              activeCurrency.type === assestType.privates ||
              activeCurrency.type === assestType.music ||
              LOAN_ASSETS[activeCurrency?.type]
            ) ||
            modifyLoading ||
            loading ||
            Number(formData.qty) === 0 ||
            UserLoading
          }
          onClick={handleButtonClick}
          className={cn(classButton, styles.button)}
        >
          {!loading && !modifyLoading ? (
            buttonText
          ) : (
            <Loader className="loader-white" dimension={30} />
          )}
        </button>
      </div>
      <ReactModal
        visible={visibleCancel}
        onClose={() => setVisibleCancel(false)}
        maskClosable={false}
        outerClassName={styles.confirmationModalOuter}
      >
        {renderConfirmation}
      </ReactModal>
      {initiateBiometric && (
        <DeviceBiometrics
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          render={(isWebAuthLoading: boolean, isModalOpen: boolean) =>
            setModalVisible(isWebAuthLoading, isModalOpen)
          }
        />
      )}
      {primaryIssuance?.signDocModal && (
        <IssuanceSignDoc onSuccess={handelOrderPlace} />
      )}
    </>
  );
};
