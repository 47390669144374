import {
  format,
  parse,
  getYear,
  getDate as getDay,
  getHours,
  getMinutes,
  differenceInMinutes,
  differenceInHours,
  parseISO,
  formatRelative,
  subDays, addMonths, addWeeks, addYears, addDays
} from "date-fns";
// @ts-ignore
import { Json } from "@types/common";

export const getTime = (dateTime: number | string | Date) => {
  const date =
    typeof dateTime === "number" || typeof dateTime === "string"
      ? new Date(dateTime)
      : dateTime;
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export const getFormattedTime = (
  date: number | string | Date,
  formatStr = "hh:mm a"
) => {
  const parsedDate =
    typeof date === "number" || typeof date === "string"
      ? parse(String(date), "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date())
      : date;
  return format(parsedDate, formatStr);
};

export const getMonthYearDay = (timeStamp: number, showTime?: boolean) => {
  const date = new Date(timeStamp);
  const month = format(date, "MMM");
  const day = getDay(date);
  const year = getYear(date);
  const time = showTime ? getTime(date) : "";
  return `${month} ${day}, ${year} ${time}`;
};

export const getDateWithTime = (
  date: number | string | Date,
  formatStr = "MMM dd, yyyy hh:mm a"
) => {
  const parsedDate =
    typeof date === "number" || typeof date === "string"
      ? parse(String(date), "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date())
      : date;
  return format(parsedDate, formatStr);
};

export const getDateWithFormatTime = (
  date: number | string | Date,
  formatStr = "MMM dd yyyy, HH:mm:ss"
) => {
  const parsedDate =
    typeof date === "number" || typeof date === "string"
      ? parse(String(date), "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date())
      : date;
  return format(parsedDate, formatStr);
};

export const formatDate = (createdAt: string): string => {
  const parsedDate = parseISO(createdAt);
  const currentDate = new Date();

  const minutesDifference = differenceInMinutes(currentDate, parsedDate);
  const hoursDifference = differenceInHours(currentDate, parsedDate);

  // Format the date based on conditions
  if (minutesDifference < 1) {
    return 'less than a minute ago';
  } else if (minutesDifference < 60) {
    return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
  } else if (hoursDifference < 24) {
    return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
  } else if (hoursDifference >= 24 && hoursDifference < 48) {
    return 'Yesterday';
  } else {
    return format(parsedDate, 'dd MMM, yyyy');
  }
};

export const formatDateWithCustomFormat = (createdAt: string, options: Json): string => {
  const parsedDate = parseISO(createdAt);
  const currentDate = new Date();

  const minutesDifference = differenceInMinutes(currentDate, parsedDate);
  const hoursDifference = differenceInHours(currentDate, parsedDate);

  const customFormat = "hh:mma, MMM dd, yyyy";

  // Format the date based on conditions
  if (minutesDifference < 1) {
    return format(parsedDate, options?.minutesFormat || customFormat);
  } else if (minutesDifference < 60) {
    return format(parsedDate, options?.minutesFormat || customFormat);
  } else if (hoursDifference < 24) {
    return format(parsedDate, options?.hourFormat || customFormat);
  } else if (hoursDifference >= 24 && hoursDifference < 48) {
    return !!options?.yesterdayFormat ? format(parsedDate, options?.yesterdayFormat) : 'Yesterday';
  } else {
    return format(parsedDate, options?.dataFormat || customFormat);
  }
};

export const formatDateWithTime = (date: string, dateFormat: string): string => {
  const parsedDate = parseISO(date);
  const currentDate = new Date();

  const hoursDifference = differenceInHours(currentDate, parsedDate);
  // shows time for under 24 hr, then date with MMM dd, yyyy format
  // Format the date based on conditions
  if (hoursDifference < 24) {
    return formatRelative(subDays(new Date(parsedDate), 0), currentDate);
  } else {
    return format(parsedDate, dateFormat);
  }
};


export const getFormattedDate = (
  date: number | string | Date,
  formatStr?: string
) => {
  let parsedDate;
  if (date) {
    if (typeof date === "number") {
      parsedDate = new Date(date);
      formatStr = "MMM dd, yyyy";
    } else if (typeof date === "string") {
      parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());
      formatStr = "MMM dd, yyyy";
    } else if (date instanceof Date) {
      parsedDate = date;
      formatStr = "yyyy-MM-dd";
    } else {
      // Handle unsupported input or default to current date
      parsedDate = new Date();
    }
  } else {
    return ""
  }


  return format(parsedDate, formatStr ?? "MMM dd, yyyy");
};

// export const isLessThanMonths = (orderDate: string,noMonths:JSON): boolean => {
//   const currentDate = new Date();
//   const orderDateObj = new Date(orderDate);
//
//   const MonthsInMilliseconds = convertToDays(noMonths) * 24 * 60 * 60 * 1000; // Approximation of 6 months
//
//   const differenceInMilliseconds =
//     currentDate.getTime() - orderDateObj.getTime();
//
//   return differenceInMilliseconds < MonthsInMilliseconds;
// };

export const convertToDays = (durationObj: any, tradeUnlockTime = 0) => {
  const { duration, unit } = durationObj || {};
  const durationInNumber = parseInt(duration)
  let days;

  switch (unit) {
    case "days":
      days = addDays(new Date(tradeUnlockTime), durationInNumber);
      break;
    case "day":
      days = addDays(new Date(tradeUnlockTime), durationInNumber);
      break;
    case "week":
      days = addWeeks(new Date(tradeUnlockTime), durationInNumber);
      break;
    case "month":
      days = addMonths(new Date(tradeUnlockTime), durationInNumber);
      break;
    case "year":
      days = addYears(new Date(tradeUnlockTime), durationInNumber);
      break;
    default:
      return {days: 0, futureDate: 0};
  }

  const futureDate = days;
  days = differenceInHours(days, new Date()) / 24;
  console.warn("differenceInDays", Math.ceil(days), new Date(tradeUnlockTime), futureDate)

  return {days: days < 0 ? 0 : Math.ceil(days), futureDate};
}
